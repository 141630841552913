<template>
    <section>
      
      <div class="columns is-gapless is-marginless is-centered is-mobile">
        <div class="column is-narrow is-mobile mt2rem">
          <MonacoEditor class="editor" v-model="code" language="json" />

        </div>
      </div>
      
      <b-notification :closable="false">
          <b-loading :is-full-page="true" :active.sync="pageLoading"></b-loading>
        </b-notification>
    </section>
</template>

<script>
// import axios_api from '@/plugins/axios_api';
/* eslint-disable */
// import { WEB_ROOT, API_ROOT } from '@/config/app.js';
import MonacoEditor from 'vue-monaco'

export default {
  name: "editor",
  
  components: {
    MonacoEditor
  },
    
  data() {
    return {
      pageLoading         : false,
      pageLoadingTimeout  : null,
      
      code: 'const noop = () => {}'
    }
  },
  
  mounted(){
    
  },
  methods: {
    
    
    async ShowPageLoading(maxDuration = 30000){
      this.pageLoading = true;
      
      if (this.pageLoadingTimeout !== null) {
        clearTimeout(this.pageLoadingTimeout);
      }
      
      this.pageLoadingTimeout = setTimeout(() => {
        this.HidePageLoading();
      }, maxDuration);
    },
    
    async HidePageLoading() {
      this.pageLoading = false;
      if (this.pageLoadingTimeout !== null) {
        clearTimeout(this.pageLoadingTimeout);
      }
    },
  }
};
</script>

<style scoped>
  .editor {
    width: 1200px;
    height: 1200px;
  }
</style>