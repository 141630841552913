<template>
  <section>
    <h1>example</h1>
    
    <div class="columns is-gapless is-marginless is-multiline m1rem">
      <MultiSelect v-model="selectOptions1" :label="'multi-select'" @change="printValuesMultiSelect(selectOptions1)" />
      <MultiSelect v-model="selectOptions2" :label="'testing2'" @change="printValuesMultiSelect(selectOptions2)" />
      <MultiSelect v-model="selectOptions3" :label="'testing3'" @change="printValuesMultiSelect(selectOptions3)" />
      
      <MultiSlider v-model="sliderValue1" :label="'multi'" :min=5 :max=50 :step="5" :ticks="false" @change="printValuesSlider" />
      <MultiSlider v-model="sliderValue2" :label="'single'" :min=5 :max=50 :step="5" :ticks="false" @change="printValuesSlider" />
      
    </div>
    
    
  </section>
</template>

<script>

// See video:
// https://www.youtube.com/watch?v=9zBsdzdE4sM

import MultiSelect from "@/components/public/general/multiSelect.vue";
import MultiSlider from "@/components/public/general/multiSlider.vue";

export default {
    name: "multiSelectExample",
    components: {
      MultiSelect,
      MultiSlider
    },
        
    data() {
      return {
        sliderValue1: [10, 25],
        sliderValue2: 25,
        
        selectOptions1: [
          { 
            label:'VIP',
            value : true 
          },{ 
            label:'Praktijkhouder',
            value : false 
          },{ 
            label:'VIP-Waarnemer',
            value : false 
          },{ 
            label:'Waarnemer',
            value : false 
          },
        ],
        selectOptions2: [
          { 
            label:'VIP',
            value : true 
          },{ 
            label:'Praktijkhouder',
            value : false 
          },{ 
            label:'VIP-Waarnemer',
            value : false 
          },{ 
            label:'Waarnemer',
            value : false 
          },
        ],
        selectOptions3: [
          { 
            label:'VIP',
            value : true 
          },{ 
            label:'Praktijkhouder',
            value : false 
          },{ 
            label:'VIP-Waarnemer',
            value : false 
          },{ 
            label:'Waarnemer',
            value : false 
          },
        ],
      }
    },
    
    watch: {
      
    },
    
    mounted(){},
    
    methods: {
      printValuesMultiSelect(){
        if (!this.selectOptions1) return
        console.info(this.selectOptions1.map(o => `${o.label}->${o.value}`))
      },
      printValuesSlider(val){
        console.info(`printValues:`, val)
        this.sliderValue1 = val
        // if (!this.sliderValue1) return
        console.info(this.sliderValue1)
      }
    }
};
</script>


<style scoped>
  

</style>