<template>
    <section>
      
      <div class="columns is-gapless is-marginless is-centered is-mobile">
        <div class="column is-narrow is-mobile mt2rem">
          <h1> Manage {{ serverName }} server</h1>
          <!-- <h2 v-if="serverState === 'ready'">
            Ready
          </h2> -->
          <h2 v-if="serverState === 'updateCode_Scheduled'">
            Update code is scheduled
          </h2>
          <h2 v-if="serverState === 'updateCode_InProgress'">
            Update code is in progress
          </h2>
          <h2 v-if="serverState === 'flushData_Scheduled'">
            Flush DATA is scheduled
          </h2>
          <h2 v-if="serverState === 'flushData_InProgress'">
            Flush DATA is in progress
          </h2>
          
          <h2 v-if="serverState === 'LOCKED'">
            SERVER IS LOCKED BY ADMIN
          </h2>
          
        </div>
      </div>
      
      <div class="columns is-gapless is-marginless is-centered is-mobile">
        <div v-if="serverState === 'ready'" class="column is-1">
          <b-button @click="flushData" type="button is-info">
            FLUSH ALL DATA
          </b-button>
        </div>
        <div v-if="serverState === 'ready'" class="column is-1">
          <b-button @click="updateCode" type="button is-danger">
            UPDATE CODE
          </b-button>
        </div>
        
        <div v-if="serverState === 'updateCode_Scheduled' || serverState === 'flushData_Scheduled'" class="column is-1">
          <b-button @click="cancelAction" type="button is-danger">
            CANCEL
          </b-button>
        </div>
      </div>
      

      <b-notification :closable="false">
          <b-loading :is-full-page="true" :active.sync="pageLoading"></b-loading>
        </b-notification>
    </section>
</template>

<script>
import axios_api from '@/plugins/axios_api';
/* eslint-disable */
import { WEB_ROOT, API_ROOT } from '@/config/app.js';

export default {
  name: "ServerManage",
  
  components: {},
    
  data() {
    return {
      pageLoading         : false,
      pageLoadingTimeout  : null,
      serverSecKey        : '',
      serverName          : '',
      serverState         : 'loading'
    }
  },
  
  mounted(){
    this.serverSecKey = this.$route.params.serverSecKey;
    this.serverName = this.$route.params.serverName;    
    this.ShowPageLoading(15000)
    this.getServerState()
    setInterval(() => {
      this.getServerState()
    }, 5*1000)
  },
  methods: {
    async getServerState() {      
      let response = await axios_api.post(`${API_ROOT}/servermanage/getState`, {
         serverSecToken: this.serverSecKey,
         serverName: this.serverName         
      });
      
      if (response.data.result !== 1) {
        this.$buefy.dialog.alert({ title: 'Error', message: 'unable to get server state', type: 'is-danger' })
        this.HidePageLoading()
        return;
      }
      
      this.serverState = response.data.payload.curState      
      console.info(`response:`, response)
      
      this.HidePageLoading()
    },
    
    
    async flushData(){      
      this.$buefy.dialog.confirm({
        title: 'FLUSHING DATA',
        message: 'This will take around 20-30 minutes, are you sure?',
        cancelText: 'No',
        confirmText: 'Yes',
        type: 'is-success',
        onConfirm: () => this.flushDataConfirmed()
      })
    },
    async flushDataConfirmed(){
      // this.$buefy.dialog.alert('Flush scheduled!')
      this.$buefy.dialog.prompt({
        title: 'SECURITY TOKEN',
        message: `Please enter your security key`,
        inputAttrs: {
          placeholder: 'security key',
          maxlength: 10
        },
        trapFocus: true,
        onConfirm: (value) => this.flushDataSubmit(value)
      })
    },
    async flushDataSubmit(value){
      this.ShowPageLoading(15000)
      const rtn = await axios_api.post(`${API_ROOT}/servermanage/setState`, {
         serverSecToken: this.serverSecKey,
         serverName: this.serverName,
         userToken: value,
         newState: 'flushData_Scheduled',
         payload: null
      });
      await this.getServerState()
    },
    
    async cancelAction(){
      this.$buefy.dialog.confirm({
        title: 'Cancel Scheduled Action ?',
        message: 'Are you sure you want to cancel?',
        cancelText: 'No',
        confirmText: 'Yes',
        type: 'is-success',
        onConfirm: () => this.cancelActionConfirmed()
      })
    },
    async cancelActionConfirmed(){
      this.$buefy.dialog.prompt({
        title: 'SECURITY TOKEN',
        message: `Please enter your security key`,
        inputAttrs: {
          placeholder: 'security key',
          maxlength: 10
        },
        trapFocus: true,
        onConfirm: (value) => this.cancelActionSubmit(value)
      })
    },
    async cancelActionSubmit(value){
      this.ShowPageLoading(15000)
      const rtn = await axios_api.post(`${API_ROOT}/servermanage/setState`, {
         serverSecToken: this.serverSecKey,
         serverName: this.serverName,
         userToken: value,
         newState: 'cancel',
         payload: null
      });
      await this.getServerState()
    },
    
    async updateCode(){
      this.$buefy.dialog.confirm({
        title: 'UPDATING CODE',
        message: 'This takes around 5 minutes, are you sure?',
        cancelText: 'No',
        confirmText: 'Yes',
        type: 'is-success',
        onConfirm: () => this.updateCodeConfirmed()
      })
    },
    async updateCodeConfirmed(){
      this.$buefy.dialog.prompt({
        title: 'SECURITY TOKEN',
        message: `Please enter your security key`,
        inputAttrs: {
          placeholder: 'security key',
          maxlength: 10
        },
        trapFocus: true,
        onConfirm: (value) => this.updateCodeSubmit(value)
      })
    },
    async updateCodeSubmit(value){
      this.ShowPageLoading(15000)
      const rtn = await axios_api.post(`${API_ROOT}/servermanage/setState`, {
         serverSecToken: this.serverSecKey,
         serverName: this.serverName,
         userToken: value,
         newState: 'updateCode_Scheduled',
         payload: null
      });
      await this.getServerState()
    },
    
    async ShowPageLoading(maxDuration = 30000){
      this.pageLoading = true;
      
      if (this.pageLoadingTimeout !== null) {
        clearTimeout(this.pageLoadingTimeout);
      }
      
      this.pageLoadingTimeout = setTimeout(() => {
        this.HidePageLoading();
      }, maxDuration);
    },
    
    async HidePageLoading() {
      this.pageLoading = false;
      if (this.pageLoadingTimeout !== null) {
        clearTimeout(this.pageLoadingTimeout);
      }
    },
  }
};
</script>

<style scoped>
</style>